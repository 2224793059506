import { graphql } from 'gatsby'
import React from 'react'
import ChooseUs from '../components/ChooseUs'
import Container from '../components/Container'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import Order from '../components/Order'
import PageBody from '../components/PageBody'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

const Service = ({
  title,
  slug,
  heroImage,
  body,
  langKey,
  backgroundColor,
  imageColor,
  color,
  metaDescription,
}) => {
  const description = metaDescription ? metaDescription.metaDescription : null

  return (
    <Layout langKey={langKey}>
      <SEO
        title={`${title} - ${config.siteTitle}`}
        pagePath={slug}
        heroImage={heroImage}
        description={description}
        serviceSEO
      />
      <Hero title={title} image={heroImage} height="30vh" color={imageColor} />
      <Container backgroundColor={backgroundColor}>
        <PageBody body={body} color={color} />
      </Container>
      <ChooseUs langKey={langKey} />
      <Order
        langKey={langKey}
        backgroundColor={props => props.theme.colors.darkGray}
        inputColor={props => props.theme.colors.gray}
      />
    </Layout>
  )
}

const ServiceTemplate = ({ data, pageContext }) => {
  const { langKey } = pageContext
  if (data && data.contentfulBusinessService)
    return (
      <Service
        imageColor={'rgba(101, 84, 192, 0.7)'}
        langKey={langKey}
        {...data.contentfulBusinessService}
      />
    )

  if (data && data.contentfulCustomerService)
    return (
      <Service
        langKey={langKey}
        imageColor={'rgba(243, 72, 125, 0.7)'}
        {...data.contentfulCustomerService}
      />
    )

  return null
}

export const query = graphql`
  query($slug: String!, $langKey: String!) {
    contentfulBusinessService(
      slug: { eq: $slug }
      language: { key: { eq: $langKey } }
    ) {
      title
      slug
      heroImage {
        title
        gatsbyImageData
      }
      body {
        childMarkdownRemark {
          timeToRead
          html
          excerpt(pruneLength: 320)
        }
      }
      metaDescription {
        metaDescription
      }
    }
  }
`

// TODO: Add this query back when customer is ready
/**
 * contentfulCustomerService(
      slug: { eq: $slug }
      language: { key: { eq: $langKey } }
    ) {
      title
      slug
      heroImage {
        title
        fluid(maxWidth: 1800) {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      body {
        childMarkdownRemark {
          timeToRead
          html
          excerpt(pruneLength: 320)
        }
      }
    }
 */

export default ServiceTemplate
