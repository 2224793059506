import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section`
  position: relative;
  min-height: 200px;
`
const BgImg = styled(GatsbyImage)`
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  min-height: 200px;

  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }

  & > img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
  }

  &::before {
    content: '';
    background: ${props => props.color};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`

const Title = styled.h1`
  font-size: 3em;
  font-weight: 600;
  position: absolute;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  padding: 0 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;

  @media (max-width: ${props => props.theme.responsive.small}) {
    font-size: 2em;
    max-width: 300px;
  }
`

const Hero = props => (
  <Wrapper>
    <BgImg
      color={props.color}
      height={props.height}
      image={props.image.gatsbyImageData}
      backgroundColor={'#eeeeee'}
    />
    <Title>{props.title}</Title>
  </Wrapper>
)

export default Hero
